import { Component, Input, TemplateRef } from '@angular/core';
import { ASSESSMENT_RESULTS } from '@ids-constants';
import { BaseComponent } from '@ids-components';

@Component({
  selector: 'app-assessment-report-device',
  templateUrl: './device-overview.component.html',
  styleUrls: ['./device-overview.component.scss'],
})
export class DeviceOverviewComponent extends BaseComponent {
  @Input() showScoreSL = false;

  _devices: any[] = [];

  get devices() {
    return this._devices;
  }

  @Input() set devices(value: any[]) {
    this._devices = value;
  }

  @Input() rightTemplate?: TemplateRef<any>;

  selectedDevice: any = null;

  ASSESSMENT_RESULTS = ASSESSMENT_RESULTS;

  constructor() {
    super();
  }
}
