import { Component, Input } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { AssessmentService } from '@ids-services';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-assessment-type-overview',
  templateUrl: './assessment-type-overview.component.html',
  styleUrls: ['./assessment-type-overview.component.scss'],
})
export class AssessmentTypeOverviewComponent extends BaseComponent {
  isLoading = false;

  _assessmentType: any = {};

  get assessmentType() {
    return this._assessmentType;
  }

  @Input() set assessmentType(value: any) {
    this._assessmentType = value;
    this.getAssessmentDetails();
  }

  constructor(private assessmentSrv: AssessmentService) {
    super();
  }

  async getAssessmentDetails() {
    await this.prepareConfigs();
    this.isLoading = true;
    this.assessmentSrv
      .getAssessmentType(this._assessmentType?.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          this._assessmentType = {
            ...this._assessmentType,
            ...(rs || {}),
            descriptionPreview: this.util.parseMarkdown(rs?.description || ''),
            numberOfQuestions: rs?.sections?.reduce((a: any, b: any) => a + (b.assigned_questions?.length || 0), 0),
          };
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }
}
