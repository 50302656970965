import { ConnectionService, HybridMonitorService, TargetDeviceService } from '@ids-services';
import { Component, effect, OnInit, signal, WritableSignal } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { CONNECTION_TYPES, INTERFACE_TYPES } from '@ids-constants';
import { Observable, finalize, forkJoin } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

export const SCAN_TYPES = {
  IDS: 'ids',
  EDGE: 'edge',
};

const IDS_FORM_PARAMS = {
  PROJECT_ID: 'project_id',
  DEVICES: 'devices',
  CONNECTION_ID: 'connection_id',
  DEVICE_ID: 'device_id',
  NETWORKS: 'networks',
  IP_ADDR: 'ip_addr',
  NETMASK: 'netmask',
  LEVEL_OF_AGGRESSION: 'level_of_aggression',
  SAFE: 'safe',
  FEATURES: 'features',
  TRIGGER_TYPE: 'trigger_type',
  SERVICES_TO_SCAN: 'services_to_scan',
  SCOPE: 'scope',
};

const EDGE_FORM_PARAMS = {
  COMMAND: 'command',
  ACTIVE_SCAN: 'active_scan',
  RUN_SCAN: 'run_scan',
};

const AGRESSION_LEVEL_INDEX: { [key: number]: string } = {
  1: 'safe',
  2: 'advanced',
  3: 'intensive',
};

const IP_ADDRESS_PATTERN = '(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)';

@Component({
  selector: 'app-shared-active-scan-form',
  templateUrl: './shared-active-scan-form.component.html',
  styleUrls: ['./shared-active-scan-form.component.scss'],
})
export class SharedActiveScanFormComponent extends BaseComponent implements OnInit {
  isLoading = false;

  step: WritableSignal<string> = signal('');

  scanType: WritableSignal<string> = signal('');

  connections: any[] = [];

  devices: any[] = [];

  scopeOptions = [
    { value: 'network', label: 'Existing Network', description: 'Only enabled physical connection are supported.' },
    { value: 'devices', label: 'Detected Devices', description: 'Only devices from enabled physical connection are supported.' },
    { value: 'ipRange', label: 'IP Range', description: 'Select a custom IP range to scan.' },
  ];

  idsConnectionOptions: any[] = [];

  idsDevices: any[] = [];

  selectedIdsDevices: any[] = [];

  selectedEdgeDevices: any[] = [];

  deviceCols = [
    { field: 'label', header: 'Name', width: 15 },
    { field: 'connection', header: 'Connection', width: 15 },
    { field: 'type', header: 'Type', width: 15 },
    { field: 'zones', header: 'Zones', width: 15 },
  ];

  selectedAggressionLevel = 1;

  aggressionLevels: any = {};

  services: any[] = [];

  form!: FormGroup;

  SCAN_TYPES = SCAN_TYPES;

  IDS_FORM_PARAMS = IDS_FORM_PARAMS;

  netmaskOptions: any[] = [...Array(33).keys()].map((i) => ({ value: i, label: `${i}` }));

  constructor(
    public dialogRef: DynamicDialogRef,
    private connectionSrv: ConnectionService,
    private hybridMonitorSrv: HybridMonitorService,
    private targetDeviceSrv: TargetDeviceService,
  ) {
    super();
    effect(() => {
      if (this.step() === 'form' && this.scanType() === SCAN_TYPES.IDS) {
        this.getData();
      }
    });
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.initForm();
    this.step.set('confirmation');
    this.scanType.set(SCAN_TYPES.IDS);
  }

  initForm() {
    this.form = new FormGroup({
      [IDS_FORM_PARAMS.SCOPE]: new FormControl('network'),
      [IDS_FORM_PARAMS.CONNECTION_ID]: new FormControl(null, Validators.required),
      [IDS_FORM_PARAMS.IP_ADDR]: new FormControl(''),
      [IDS_FORM_PARAMS.NETMASK]: new FormControl(24),
      [IDS_FORM_PARAMS.SERVICES_TO_SCAN]: new FormControl([], Validators.required),
    });
    setTimeout(() => {
      this.form?.get(IDS_FORM_PARAMS.SCOPE)?.valueChanges?.subscribe((value) => {
        this.form?.get(IDS_FORM_PARAMS.CONNECTION_ID)?.setValidators(value === 'network' ? Validators.required : Validators.nullValidator);
        this.form
          ?.get(IDS_FORM_PARAMS.IP_ADDR)
          ?.setValidators(
            value === 'ipRange' ? Validators.compose([Validators.required, Validators.pattern(IP_ADDRESS_PATTERN)]) : Validators.nullValidator,
          );
        this.form?.get(IDS_FORM_PARAMS.NETMASK)?.setValidators(value === 'ipRange' ? Validators.required : Validators.nullValidator);
        this.form?.get(IDS_FORM_PARAMS.CONNECTION_ID)?.updateValueAndValidity();
        this.form?.get(IDS_FORM_PARAMS.IP_ADDR)?.updateValueAndValidity();
        this.form?.get(IDS_FORM_PARAMS.NETMASK)?.updateValueAndValidity();
      });
    });
  }

  getData() {
    this.isLoading = true;
    forkJoin({
      connections: this.connectionSrv.getConnections(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId),
      capabilities: this.hybridMonitorSrv.getCapabilities(),
      devices: this.targetDeviceSrv.getDevices({
        organizationId: this.breadcrumbConfig?.organizationId,
        projectId: this.breadcrumbConfig?.projectId,
        detailed: false,
      }),
    })
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.setIdsData();
        }),
      )
      .subscribe({
        next: (res) => {
          this.connections = (res?.connections?.data as any[]) || [];

          this.aggressionLevels = res?.capabilities?.level_of_aggression || {};
          this.services = (res?.capabilities?.services || []).map((s: string) => ({ value: s, label: s.toUpperCase() }));

          this.devices = (res?.devices?.devices as any[]) || [];
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  setIdsData() {
    this.idsConnectionOptions = this.connections
      .filter((c) => c.interface?.type === CONNECTION_TYPES.PHYSICAL && !!c.enabled)
      .map((c) => ({ value: c.id, label: c.name }));

    const connectionIds = (this.idsConnectionOptions || []).map((c) => c.value);
    const devices = this.devices
      .filter(
        (d) => !!Object.keys(d[INTERFACE_TYPES.IP] || {}).length && ((d.connection_ids || []) as any[]).some((id) => connectionIds.includes(id)),
      )
      .map((d) => ({
        ...d,
        connectionOptions: this.connections
          .filter((c) => ((d.connection_ids as any[]) || []).includes(c.id))
          .map((c) => ({
            label: c.name,
            value: c.id,
            type: c.interface?.type,
          })),
      }))
      .map((d) => ({ ...d, defaultConnectionId: d.connectionOptions[0]?.value }));
    this.idsDevices = devices;

    this.form?.get(IDS_FORM_PARAMS.CONNECTION_ID)?.setValue(this.idsConnectionOptions?.[0]?.value || null);
    this.form?.get(IDS_FORM_PARAMS.SERVICES_TO_SCAN)?.setValue(this.services.map((s) => s.value));
    this.form?.get(IDS_FORM_PARAMS.CONNECTION_ID)?.updateValueAndValidity();
    this.form?.get(IDS_FORM_PARAMS.SERVICES_TO_SCAN)?.updateValueAndValidity();
  }

  onSubmit() {
    if (this.scanType() === SCAN_TYPES.IDS) {
      this.startIdsActiveScan();
    } else {
      this.startEdgeActiveScan();
    }
  }

  startIdsActiveScan() {
    this.isLoading = true;
    const values = this.form.getRawValue();
    const payload = {
      [IDS_FORM_PARAMS.PROJECT_ID]: this.breadcrumbConfig?.projectId,
      ...(values[IDS_FORM_PARAMS.SCOPE] !== 'ipRange'
        ? {
            [IDS_FORM_PARAMS.DEVICES]: [
              ...(values[IDS_FORM_PARAMS.SCOPE] === 'devices'
                ? this.selectedIdsDevices?.map((device) => ({
                    [IDS_FORM_PARAMS.DEVICE_ID]: device.id,
                    [IDS_FORM_PARAMS.CONNECTION_ID]: device.defaultConnectionId,
                  })) || []
                : [
                    {
                      [IDS_FORM_PARAMS.CONNECTION_ID]: values[IDS_FORM_PARAMS.CONNECTION_ID],
                    },
                  ]),
            ],
          }
        : {
            [IDS_FORM_PARAMS.NETWORKS]: [
              { [IDS_FORM_PARAMS.IP_ADDR]: values[IDS_FORM_PARAMS.IP_ADDR], [IDS_FORM_PARAMS.NETMASK]: values[IDS_FORM_PARAMS.NETMASK] },
            ],
          }),
      [IDS_FORM_PARAMS.LEVEL_OF_AGGRESSION]: {
        [AGRESSION_LEVEL_INDEX[this.selectedAggressionLevel]]: this.aggressionLevels[AGRESSION_LEVEL_INDEX[this.selectedAggressionLevel]] || {},
      },
      [IDS_FORM_PARAMS.SERVICES_TO_SCAN]: values[IDS_FORM_PARAMS.SERVICES_TO_SCAN],
    };
    this.hybridMonitorSrv
      .startActiveScan(payload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          this.dialogRef?.close({ scanType: SCAN_TYPES.IDS, data: rs });
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  startEdgeActiveScan() {
    const payload = {
      [EDGE_FORM_PARAMS.COMMAND]: {
        [EDGE_FORM_PARAMS.ACTIVE_SCAN]: {
          [EDGE_FORM_PARAMS.RUN_SCAN]: 'true',
        },
      },
    };
    const requests: Observable<any>[] = this.selectedEdgeDevices.map((d) => this.targetDeviceSrv.sendDeviceCommand(d.id, payload));
    forkJoin(requests)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          this.dialogRef?.close({ scanType: SCAN_TYPES.EDGE, data: rs });
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  onDeviceConnectionChange(device: any) {
    const index = this.selectedIdsDevices?.findIndex((selectedDevice) => selectedDevice.id === device.id) || -1;
    if (index > -1) {
      this.selectedIdsDevices[index] = device;
    }
  }

  markFieldAsTouchedAndDirty(formName: string) {
    this.form?.get(formName)?.markAsTouched();
    this.form?.get(formName)?.markAsDirty();
  }

  onCancel() {
    this.dialogRef.close();
  }

  onConfirm() {
    this.step.set('form');
    if (this.scanType() === SCAN_TYPES.EDGE) {
      this.selectedAggressionLevel = 1;
    }
  }

  get isValid() {
    return this.scanType() === SCAN_TYPES.IDS
      ? !(this.form.invalid || (this.form.get(IDS_FORM_PARAMS.SCOPE)?.value === 'devices' && !this.selectedIdsDevices.length))
      : !!this.selectedEdgeDevices.length && this.selectedAggressionLevel === 1;
  }
}
