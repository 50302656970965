import { NETWORK_MAP_DIAGRAM_IDS, NETWORK_MAP_MODE_VALUES } from '@ids-constants';
import { NetworkMap } from './network-map';
import * as d3 from 'd3';

export const NMZoom = {
  /**
   * Update zoom in/out
   * @param option
   */
  updateZoom(this: NetworkMap, option: number | null = null) {
    const diagram = this.diagram();
    const transform = () => {
      const transform = d3.zoomIdentity.translate(diagram.currentZoomShiftX, diagram.currentZoomShiftY).scale(diagram.currentScale);
      if (!!diagram.svgContainer?.call) {
        diagram.svgContainer.call(diagram.zoom.transform, transform);
      }
    };
    if (option !== null) {
      if (option !== 0) {
        // Update zoom in/out
        const updatedZoomLevel = diagram.currentScale + 0.1 * option;
        if (updatedZoomLevel >= 0.1 && updatedZoomLevel <= 5) {
          diagram.currentScale = updatedZoomLevel;
          diagram.currentZoomShiftX += this.viewX * 0.1 * option * -1;
          diagram.currentZoomShiftY += this.viewY * 0.1 * option * -1;
          transform();
        }
      } else {
        const selectedDevice = this.selection()?.data;
        if (!!selectedDevice) {
          // Zoom to target device
          d3.selectAll(`g.${NETWORK_MAP_DIAGRAM_IDS.DEVICE_NODE}`).each((data: any) => {
            const groupId = `${selectedDevice?.status}-node-group-${selectedDevice?.id}`;
            if (data.groupId === groupId) {
              NMZoom.zoomToTarget.call(this, { target: data.mainShape.node() } as MouseEvent);
            }
          });
        } else {
          // Zoom to full diagram
          NMZoom.zoomToTarget.call(this);
        }
      }
    } else {
      transform();
    }
  },
  /**
   * Zoom to device's node(s)
   * @param this
   * @param event
   * @param zoomedDeviceIds
   */
  zoomToTarget(this: NetworkMap, event?: MouseEvent, zoomedDeviceIds: any[] = []) {
    let elementX = 0;
    let elementY = 0;
    this.diagram.update((diagram) => {
      if (!!event?.target) {
        // Clicked element
        const item = event.target as SVGCircleElement | SVGElement;
        const element = item.closest('g') as SVGGElement;
        elementX = +(element?.getAttribute('cx') || 0);
        elementY = +(element?.getAttribute('cy') || 0);
        // New scale
        diagram.currentScale = 2;
      } else {
        // Zoom all devices
        if (event?.target === undefined) {
          // Normal zoom
          let devices: any[] = [];
          switch (this.mode()) {
            case NETWORK_MAP_MODE_VALUES.FORCE_DIRECTED: {
              devices = this.forceDirectedData().devices;
              break;
            }
            case NETWORK_MAP_MODE_VALUES.PURDUE_MODEL: {
              devices = this.purdueModelData().devices;
              break;
            }
            default: {
              break;
            }
          }
          if (devices.length) {
            // Zoom all devices
            const nodesGroupElement = d3.select(`g.${NETWORK_MAP_DIAGRAM_IDS.DEVICE_NODES_GROUP}`).node() as SVGGElement;
            const nodesGroup = nodesGroupElement?.getBBox();
            if (!!nodesGroup) {
              // New scale
              diagram.currentScale = Math.min(this.windowWidth / nodesGroup.width, this.windowHeight / nodesGroup.height) * diagram.zoomScaleDiff;
              elementX = nodesGroup.x * diagram.zoomScaleDiff + nodesGroup.width / 2;
              elementY = nodesGroup.y * diagram.zoomScaleDiff + nodesGroup.height / 2;
            }
          }
        }
        // Zoom to a group of devices
        else if (event?.target === null) {
          const boundaryRect = {
            x: Infinity,
            y: Infinity,
            maxX: -Infinity,
            maxY: -Infinity,
            width: -Infinity,
            height: -Infinity,
          };
          d3.selectAll(`g.${NETWORK_MAP_DIAGRAM_IDS.DEVICE_NODE}`)
            .nodes()
            .forEach((node: any) => {
              const data: any = d3.select(node).data()?.[0];
              if (!!zoomedDeviceIds.includes(data?.id)) {
                const element = node.closest('g') as SVGGElement;
                const x = +(element.getAttribute('cx') || 0);
                const y = +(element.getAttribute('cy') || 0);
                boundaryRect.x = Math.min(boundaryRect.x, x);
                boundaryRect.y = Math.min(boundaryRect.y, y);
                boundaryRect.maxX = Math.max(boundaryRect.maxX, x);
                boundaryRect.maxY = Math.max(boundaryRect.maxY, y);
                boundaryRect.width = Math.abs(boundaryRect.maxX - boundaryRect.x);
                boundaryRect.height = Math.abs(boundaryRect.maxY - boundaryRect.y);
              }
            });
          if (zoomedDeviceIds.length > 1) {
            const widthRadius = !!boundaryRect.width ? this.windowWidth / boundaryRect.width : this.windowWidth;
            const heightRadius = !!boundaryRect.height ? this.windowHeight / boundaryRect.height : this.windowHeight;
            const scale = Math.min(widthRadius, heightRadius) * (diagram.zoomScaleDiff - 0.4);
            diagram.currentScale = Math.max(Math.min(scale, diagram.maxScaleLimit), diagram.minScaleLimit);
            elementX = boundaryRect.x * (diagram.zoomScaleDiff + 0.25) + boundaryRect.width / 2;
            elementY = boundaryRect.y * (diagram.zoomScaleDiff + 0.2) + boundaryRect.height / 2;
          } else {
            elementX = boundaryRect.x;
            elementY = boundaryRect.y;
            diagram.currentScale = 2;
          }
        }
      }
      /** CALCULATION X
       * -elementX * this.currentScale            => The correct position of the node, this will make sure the node will be always at left edge of the screen
       * + this.windowWidth * 0.25                => 0.25 to make sure the x will be located in position 1/4 of screen width
       * - this.viewX * (this.currentScale - 1)   => Additional value to make it locate to the position (0,y) of the svg
       */
      diagram.currentZoomShiftX = -elementX * diagram.currentScale + this.windowWidth * 0.25 - this.viewX * (diagram.currentScale - 1);
      /** CALCULATION Y
       * -elementY * this.currentScale            => The correct position of the node, this will make sure the node will be always at top edge corner of the screen
       * + this.windowHeight * 0.5                => 0.5 to make sure the x will be located in middle of screen height
       * - this.viewY * (this.currentScale - 1)   => Additional value to make it locate to the position (x,0) of the svg
       */
      diagram.currentZoomShiftY = -elementY * diagram.currentScale + this.windowHeight * 0.5 - this.viewY * (diagram.currentScale - 1);

      const transform = d3.zoomIdentity.translate(diagram.currentZoomShiftX, diagram.currentZoomShiftY).scale(diagram.currentScale);
      if (!!diagram.svgContainer?.transition) {
        diagram.svgContainer.transition()?.duration(500)?.call(diagram.zoom.transform, transform);
      }
      return diagram;
    });
  },
};
