import { OnInit, Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { AssessmentService } from '@ids-services';
import {
  ASSESSMENT_ANSWERS,
  ASSESSMENT_REPORT_STATUS,
  ASSESSMENT_STATUS,
  ATTACHMENT_FILE_ICONS,
  ATTACHMENT_TYPE_FILES,
  MAX_ATTACHMENT_FILE_SIZE,
} from '@ids-constants';

import { MenuItem } from 'primeng/api';
import { Observable, finalize, forkJoin } from 'rxjs';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { AssessmentPreviewInfoComponent } from './assessment-preview-info/assessment-preview-info.component';
import { UploadFileButtonComponent } from '@microsec/components';

@Component({
  selector: 'app-assessment-preview',
  templateUrl: './assessment-preview.component.html',
  styleUrls: ['./assessment-preview.component.scss'],
})
export class AssessmentPreviewComponent extends BaseComponent implements OnInit {
  isLoading = false;

  @Input() isAssessmentType = false;

  _assessment: any = null;

  get assessment() {
    return this._assessment;
  }

  @Input() set assessment(value: any) {
    this._assessment = value;
    this.getData(() => {
      this.setData();
    });
  }

  @Output() closeAssessmentTypePreviewEvent: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('uploadButton') uploadButton!: UploadFileButtonComponent;

  isPrefilled = false;

  isValid = false;

  progress = 0;

  activeIndexSidebarAccordion = 0;

  activeIndexQuestion = 0;

  firstUnansweredIndexQuestion = 0;

  sortedSections: any[] = [];

  sortedQuestions: any[] = [];

  questions: { [key: string]: any } = {};

  selectedQuestion: any = {};

  questionClearOptions: MenuItem[] = [
    {
      label: 'Clear Response',
      command: () => {
        if (this.question) {
          this.clearResponse();
          this.onAnswerChange();
        }
      },
    },
    {
      label: 'Clear Comments',
      command: () => {
        if (this.question) {
          this.question.comment = '';
          this.onAnswerChange();
        }
      },
    },
    {
      label: 'Clear Attachments',
      command: () => {
        if (this.question) {
          this.onDeleteAttachment(this.question.attachments || []);
        }
      },
    },
    {
      label: 'Clear All',
      command: () => {
        if (this.question) {
          this.onDeleteAttachment(this.question.attachments || [], () => {
            this.question.comment = '';
            this.clearResponse();
            this.onAnswerChange();
          });
        }
      },
    },
  ];

  ASSESSMENT_ANSWERS = ASSESSMENT_ANSWERS;

  ATTACHMENT_FILE_ICONS = ATTACHMENT_FILE_ICONS;

  ATTACHMENT_TYPE_FILES = ATTACHMENT_TYPE_FILES;

  constructor(
    private assessmentSrv: AssessmentService,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    await this.prepareConfigs();
    if (!this.isAssessmentType) {
      this.assessment = this.dialogConfig?.data?.assessment;
    }
  }

  getData(callback: () => void) {
    this.isLoading = true;
    forkJoin({
      assessment: this.assessmentSrv.getAssessment(this._assessment?.id),
      assessmentType: this.assessmentSrv.getAssessmentType(this._assessment?.assessment_type?.id),
    })
      .pipe(
        finalize(() => {
          this.isLoading = false;
          callback();
        }),
      )
      .subscribe({
        next: (rs) => {
          this._assessment = {
            ...rs.assessment,
            devices: (rs.assessment?.devices as any[]) || [],
          };
          this.assessment.assessment_type = rs.assessmentType;
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  setData() {
    let index = -1;
    const sortedSections: any[] = [];
    const sortedQuestions: any[] = [];
    const questions: { [key: string]: any } = {};
    const deviceObjs: any[] = (this.assessment?.devices as any[]) || [];
    this.util.sortObjectArray(this.assessment?.assessment_type?.sections || [], 'position').forEach((section, indexSection) => {
      const assignedQuestions: any[] = [];
      this.util.sortObjectArray(section.assigned_questions || [], 'position').forEach((question) => {
        index++;
        assignedQuestions.push({
          ...question,
          index,
          indexSidebarAccordion: indexSection,
        });
        sortedQuestions.push({
          ...question,
          descriptionPreview: this.util.parseMarkdown(question.question?.description || ''),
          sectionId: section?.id || this.sortedSections?.[0]?.id || null,
          sectionName: section?.name || this.sortedSections?.[0]?.name || '',
          index,
          indexSidebarAccordion: indexSection,
        });
        questions[question.question.id] = {
          id: question.question.id,
          mandatory: question.mandatory,
          devices: deviceObjs.map((device: any) => ({
            ...device,
            ...(!!question.devices?.length ? question.devices.find((d: any) => d.device_id === device.device_id) || {} : {}),
          })),
          deviceAnswers: deviceObjs.reduce((arr: any, device: any) => ({ ...arr, [device.device_id]: null }), {}),
          generalAnswers: null,
          comment: '',
        };
      });
      sortedSections.push({
        ...section,
        assignedQuestions: assignedQuestions,
      });
    });
    ((this.assessment?.questions as any[]) || []).forEach((q) => {
      if (!!questions[q.assessment_question_id]) {
        const deviceAnswers: any = {};
        if (!!questions[q.assessment_question_id].deviceAnswers) {
          ((q.answers as any[]) || []).forEach((answer) => {
            ((answer.device_ids as any[]) || []).forEach((deviceId) => {
              deviceAnswers[deviceId] = answer.value;
            });
          });
        }
        questions[q.assessment_question_id] = {
          ...questions[q.assessment_question_id],
          ...q,
          devices: deviceObjs.map((device: any) => ({
            ...device,
            ...(!!q.devices?.length ? q.devices.find((d: any) => d.device_id === device.device_id) || {} : {}),
          })),
          attachments: (q.attachments || []).map((attachment: any) => ({
            id: attachment.id,
            file_name: attachment.file_name,
            file_size: attachment.file_size,
            file_type: attachment.file_type,
          })),
          generalAnswers: q.answers?.[0] ? q.answers[0].value : null,
          deviceAnswers: {
            ...questions[q.assessment_question_id].deviceAnswers,
            ...deviceAnswers,
          },
        };
      }
    });
    this.sortedSections = sortedSections;
    this.sortedQuestions = sortedQuestions;
    this.questions = questions;
    this.checkValidity();
    this.changeStep();
  }

  onAnswerChange() {
    this.checkValidity();
    if (!this.isAssessmentType) {
      this.saveAnswer(false, false, this.question);
    }
  }

  saveAnswer(isSubmit = false, isCompleted = false, question: any = null) {
    const payload = this.getPayload(question, isCompleted);
    this.updateAssessment(payload, isSubmit, isSubmit);
  }

  updateAssessment(payload: any, isSubmit = false, isCompleted = false) {
    this.isLoading = true;
    this.assessmentSrv
      .updateAssessment(this.assessment?.id, payload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          if (!!isSubmit) {
            this.showSuccessMessage(
              rs.status === ASSESSMENT_STATUS.COMPLETED && rs.report_status !== ASSESSMENT_REPORT_STATUS.COMPLETED
                ? 'Assessment submitted. Results are now being processed and will be available in a few minutes.'
                : `Assessment ${rs.status === ASSESSMENT_STATUS.COMPLETED ? 'Completed' : 'Saved'} Successfully`,
            );
          }
          if (rs.status === ASSESSMENT_STATUS.COMPLETED) {
            this.assessmentSrv
              .getAssessmentReport(this.assessment?.id)
              .pipe()
              .subscribe();
          }
          if (!!isSubmit || !!isCompleted) {
            this.dialogRef.close(rs);
          }
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  getPayload(question: any, isCompleted = false) {
    const payload: any = {};
    if (!!question && !isCompleted) {
      const deviceAnswers: any = {};
      Object.keys(question.deviceAnswers || {}).forEach((deviceId) => {
        const answer: any = question.deviceAnswers[deviceId];
        if (answer !== null) {
          if (deviceAnswers[answer] !== undefined) {
            deviceAnswers[answer].push(parseInt(deviceId));
          } else {
            deviceAnswers[answer] = [parseInt(deviceId)];
          }
        }
      });
      const answers = !!this.assessment?.devices?.length
        ? Object.keys(deviceAnswers || {}).map((value) => ({
            value: value === ASSESSMENT_ANSWERS.NOT_APPLICABLE ? ASSESSMENT_ANSWERS.NOT_APPLICABLE : value === 'true',
            device_ids: deviceAnswers[value] || [],
          }))
        : question.generalAnswers !== null
          ? [
              {
                value: question.generalAnswers,
              },
            ]
          : [];
      payload.questions = [
        {
          answers: answers?.length ? answers : [{ value: null }],
          devices:
            !!this.assessment?.devices?.length && answers?.length
              ? (question.devices || [])
                  .filter((d: any) => !!d.is_prefilled)
                  .map((d: any) => ({
                    device_id: d.device_id,
                    is_prefilled: d.is_prefilled,
                    prefilled_answer: d.prefilled_answer,
                    user_confirmed: d.user_confirmed,
                    user_answer: d.user_answer,
                  }))
              : undefined,
          attachments: question.attachments || [],
          comment: question.comment || '',
          question_id: question.id,
          status: answers?.length ? 'answered' : 'unanswered',
        },
      ];
    } else if (!!isCompleted) {
      payload.status = ASSESSMENT_STATUS.COMPLETED;
    }
    return payload;
  }

  clearResponse() {
    const deviceObjs: any[] = (this.assessment?.devices as any[]) || [];
    this.question.deviceAnswers = deviceObjs.reduce((arr: any[], device: any) => ({ ...arr, [device.device_id]: null }), {});
    this.question.generalAnswers = null;
    this.question.devices = (this.question.devices || []).map((d: any) => ({
      ...d,
      user_confirmed: false,
      user_answer: null,
    }));
  }

  checkValidity() {
    Object.values(this.questions || {}).forEach((question) => {
      question.isValid = !question.mandatory
        ? true
        : !!this.assessment?.devices?.length
          ? !!Object.values(question.deviceAnswers || {}).every((answer) => answer !== null)
          : question.generalAnswers !== null;
      question.completed = !!this.assessment?.devices?.length
        ? !!Object.values(question.deviceAnswers || {}).every((answer) => answer !== null)
        : question.generalAnswers !== null;
    });
    this.isValid = !!Object.values(this.questions || {}).every((question) => !!question.isValid);
    this.progress = Object.values(this.questions || {}).filter((question) => !!question.completed)?.length || 0;
    this.sortedQuestions?.every((question, questionIndex) => {
      if (!this.questions?.[question.question.id]?.completed) {
        this.firstUnansweredIndexQuestion = questionIndex;
        return false;
      }
      return true;
    });
  }

  onUploadAttachment(event: any) {
    const file: any = (event.target.files as FileList).item(0);
    if (file && !!this.selectedQuestion.question?.id) {
      this.isLoading = true;
      if (file.size > MAX_ATTACHMENT_FILE_SIZE) {
        this.isLoading = false;
        this.showErrorMessage('Maximum file size is 1MB');
        return;
      }
      const formData = new FormData();
      formData.append('assessment_id', this.assessment?.id);
      formData.append('assessment_question_id', this.selectedQuestion.question.id.toString());
      formData.append('file_path', file);
      this.assessmentSrv
        .uploadAssessmentAttachment(formData)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          }),
        )
        .subscribe({
          next: (res) => {
            this.showSuccessMessage('Uploaded Attachment Successfully');
            if (this.question) {
              this.question.attachments = [
                ...(this.question.attachments || []),
                { id: res.id, file_name: res.file_name, file_size: res.file_size, file_type: res.file_type },
              ];
              this.onAnswerChange();
            }
          },
          error: (err: any) => {
            this.showErrorMessage(err);
          },
        });
    }
    const input: any = document.getElementById(`file-${this.uploadButton?.id}`);
    if (!!input) {
      input.value = '';
    }
  }

  onDeleteAttachment(attachments: any[], onSuccess?: (rs?: any) => void) {
    this.isLoading = true;
    const attachmentIds = (attachments || []).map((attachment) => attachment.id);
    const requests: Observable<any>[] = attachmentIds.map((id) => this.assessmentSrv.deleteAssessmentAttachment(id));
    forkJoin(requests)
      .pipe(
        finalize(() => {
          if (this.question) {
            this.question.attachments = this.question.attachments.filter((attachment: any) =>
              !!attachmentIds.length ? !attachmentIds.includes(attachment.id) : true,
            );
          }
          if (!!onSuccess) {
            onSuccess?.();
          } else {
            this.onAnswerChange();
          }
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {},
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
    if (!attachmentIds.length) {
      onSuccess?.();
    }
  }

  downloadAttachment(attachment: any) {
    this.isLoading = true;
    this.assessmentSrv
      .downloadAssessmentAttachment(attachment?.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.showSuccessMessage('Downloaded Attachment Successfully');
          this.util.downloadClientFile(`${attachment?.file_name}`, res);
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  openAssessmentPreviewInfo() {
    this.dialogSrv.open(AssessmentPreviewInfoComponent, {
      data: { assessment: this.assessment },
      header: this.util.getUppercaseFirstLetter(this.assessment.name),
      width: '900px',
      height: 'min-content',
      closeOnEscape: true,
    });
  }

  onSelectSection(index: number) {
    const section = this.sortedSections[index];
    const question = section?.assignedQuestions[0];
    const questionIndex = this.sortedQuestions.findIndex((q) => q.question?.id === question?.question?.id);
    this.changeStep(questionIndex);
  }

  changeStep(index?: number) {
    this.selectedQuestion = this.util.cloneDeepObject(this.sortedQuestions[(index || 0) >= 0 ? index || 0 : this.firstUnansweredIndexQuestion] || {});
    this.activeIndexQuestion = this.selectedQuestion.index;
    this.activeIndexSidebarAccordion = this.selectedQuestion.indexSidebarAccordion;
  }

  get question() {
    return this.questions?.[this.selectedQuestion?.question?.id] || null;
  }
}
