import { ILayoutItem } from '@microsec/interfaces';

import { AssessmentsComponent } from './assessments/assessments.component';
import { AssessmentTypesComponent } from './assessment-types/assessment-types.component';
import { AssessmentQuestionsComponent } from './assessment-questions/assessment-questions.component';
import { AssessmentPreviewComponent } from './assessments/assessment-preview/assessment-preview.component';
import { SharedAssessmentReportComponent } from '../../../shared-components/shared-assessment-report/shared-assessment-report.component';

export const ASSESSMENT_TOOL_CONSTANTS = {
  ASSESSMENTS: {
    ROUTE: 'assessments',
    LABEL: 'Assessments',
  },
  ASSESSMENT_PREVIEW: {
    ROUTE: ':assessmentId/preview',
    LABEL: 'Assessment Preview',
  },
  ASSESSMENT_REPORT: {
    ROUTE: ':assessmentId/report',
    LABEL: 'Assessment Report',
  },
  ASSESSMENT_TYPES: {
    ROUTE: 'assessment-types',
    LABEL: 'Assessment Types',
  },
  QUESTION_BANK: {
    ROUTE: 'question-bank',
    LABEL: 'Question Bank',
  },
};

export const ASSESSMENT_TOOL_CHILDREN: ILayoutItem[] = [
  {
    label: '',
    route: ASSESSMENT_TOOL_CONSTANTS.ASSESSMENTS.ROUTE,
  },
  {
    route: ASSESSMENT_TOOL_CONSTANTS.ASSESSMENTS.ROUTE,
    label: ASSESSMENT_TOOL_CONSTANTS.ASSESSMENTS.LABEL,
    component: AssessmentsComponent,
  },
  {
    route: `${ASSESSMENT_TOOL_CONSTANTS.ASSESSMENTS.ROUTE}/${ASSESSMENT_TOOL_CONSTANTS.ASSESSMENT_PREVIEW.ROUTE}`,
    label: ASSESSMENT_TOOL_CONSTANTS.ASSESSMENT_PREVIEW.LABEL,
    component: AssessmentPreviewComponent,
    visible: false,
  },
  {
    route: `${ASSESSMENT_TOOL_CONSTANTS.ASSESSMENTS.ROUTE}/${ASSESSMENT_TOOL_CONSTANTS.ASSESSMENT_REPORT.ROUTE}`,
    label: ASSESSMENT_TOOL_CONSTANTS.ASSESSMENT_REPORT.LABEL,
    component: SharedAssessmentReportComponent,
    visible: false,
  },
  {
    route: ASSESSMENT_TOOL_CONSTANTS.ASSESSMENT_TYPES.ROUTE,
    label: ASSESSMENT_TOOL_CONSTANTS.ASSESSMENT_TYPES.LABEL,
    component: AssessmentTypesComponent,
  },
  {
    route: ASSESSMENT_TOOL_CONSTANTS.QUESTION_BANK.ROUTE,
    label: ASSESSMENT_TOOL_CONSTANTS.QUESTION_BANK.LABEL,
    component: AssessmentQuestionsComponent,
  },
];
