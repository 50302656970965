<microsec-loading-overlay [isLoading]="!!isLoading">
  <div class="assessment-report" [autofocus]="true" tabindex="-1">
    <microsec-common-preview-layout [headerTemplate]="headerTemplate">
      <div class="body">
        <div class="grid">
          <div class="col-12">
            <div class="grid">
              <div class="col-4">
                <microsec-card [isBordered]="true" [isFull]="true">
                  <div class="flex flex-column justify-content-between align-items-stretch h-full gap-2">
                    <div class="flex flex-column gap-2 w-full align-self-baseline">
                      <div class="font-bold">Compliance Scorecard</div>
                      <div class="flex w-full justify-content-center">
                        @if (assessmentReport?.displayScoreOverall === 0 || assessmentReport?.displayScoreOverall > 0) {
                          <ngx-gauge
                            class="custom-gauge"
                            type="full"
                            [value]="assessmentReport.displayScoreOverall"
                            [thick]="20"
                            [size]="275"
                            [thresholds]="gauge.thresholds"
                            [animate]="false"
                            append="%"
                            backgroundColor="#2c2c2c" />
                        } @else {
                          <div class="flex justify-content-center">
                            <span class="text-7xl font-bold">—</span>
                          </div>
                        }
                      </div>
                    </div>
                    <div class="flex relative w-full h-full overflow-y-auto">
                      <div class="flex flex-column w-full gap-2 absolute pr-1">
                        @for (section of assessmentReport?.sections || []; track $index) {
                          <div
                            class="section-card"
                            [ngClass]="{
                              green: getResult(section.result) === ASSESSMENT_RESULTS.COMPLIANT,
                              orange: getResult(section.result) === ASSESSMENT_RESULTS.PARTIALLY_COMPLIANT,
                              red: getResult(section.result) === ASSESSMENT_RESULTS.NOT_COMPLIANT
                            }">
                            {{ section.section_name }}
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </microsec-card>
              </div>
              <div class="col-8 pb-0">
                <div class="grid">
                  <div class="col-12">
                    <microsec-card [isBordered]="true" [isFull]="true">
                      <div class="font-bold mb-3">Cybersecurity Posture Benchmark</div>
                      <div class="mx-3">
                        According to MicroIDS's benchmark analysis, your current cybersecurity posture is rated
                        <span
                          [ngClass]="{
                            'text-green': assessmentReport?.result_overall === ASSESSMENT_RESULTS.COMPLIANT,
                            'text-orange': assessmentReport?.result_overall === ASSESSMENT_RESULTS.PARTIALLY_COMPLIANT,
                            'text-red': assessmentReport?.result_overall === ASSESSMENT_RESULTS.NOT_COMPLIANT,
                            'text-grey':
                              assessmentReport?.result_overall === ASSESSMENT_RESULTS.UNANSWERED ||
                              assessmentReport?.result_overall === ASSESSMENT_RESULTS.NOT_APPLICABLE
                          }">
                          {{
                            assessmentReport?.displayScoreOverall === 0 || assessmentReport?.displayScoreOverall > 0
                              ? assessmentReport.displayScoreOverall + '%'
                              : '—'
                          }}</span
                        >.
                        <span *ngIf="assessmentReport?.displayScoreOverall === 100"
                          >Well done. It is recommended to periodically assess your cybersecurity posture.
                        </span>
                        <span *ngIf="assessmentReport?.displayScoreOverall !== 100">
                          <span *ngIf="assessmentReport?.displayScoreOverall > 0">However, there is still room for improvement.</span>
                          To enhance security measures, refer to the results below for detailed information on non-compliant devices and their
                          corresponding recommended remediation steps.
                        </span>
                      </div>
                    </microsec-card>
                  </div>
                  @if (!!assessment?.devices?.length) {
                    <div class="col-3">
                      <microsec-card [isFull]="true" [isBordered]="true">
                        <div class="flex flex-column gap-2">
                          <div class="flex align-items-center gap-2">
                            <label class="text-xl font-light text-gradient-blue">
                              {{ ((assessmentReport?.final_confidence_score || 0) * 100 | number: '1.0-0') + '%' }}
                            </label>
                            <img src="/assets/images/prefilling-icon.svg" alt="prefilling-icon" />
                          </div>
                          <div>Confidence Score</div>
                        </div>
                      </microsec-card>
                    </div>
                  }
                  <div class="col-3">
                    <microsec-card [isFull]="true" [isBordered]="true">
                      <div class="flex flex-column gap-2">
                        <div class="text-xl font-light text-highlight">
                          {{ assessment?.devices?.length }}
                        </div>
                        <div>Device(s) Assessed</div>
                      </div>
                    </microsec-card>
                  </div>
                  <div class="col-3">
                    <microsec-card [isFull]="true" [isBordered]="true">
                      <div class="flex flex-column gap-2">
                        <div class="text-xl font-light text-highlight">
                          {{ questionCount.answered || 0 }}/{{ assessmentReport?.questions?.length }}
                        </div>
                        <div>Question(s) Answered</div>
                      </div>
                    </microsec-card>
                  </div>
                  <div class="col-3">
                    <microsec-card [isFull]="true" [isBordered]="true">
                      <div class="flex flex-column gap-2">
                        <div class="text-xl font-light text-highlight">
                          {{ questionCount.unanswered || 0 }}/{{ assessmentReport?.questions?.length }}
                        </div>
                        <div>Question(s) Skipped</div>
                      </div>
                    </microsec-card>
                  </div>
                  @if (!!assessmentReport?.devices?.length) {
                    <div class="col-6">
                      <microsec-card [isBordered]="true" [isChartCard]="true" [isFull]="true">
                        <div class="font-bold mb-3">Device Distribution by Compliance</div>
                        <div class="my-auto">
                          <microsec-common-chart
                            #deviceComplianceDiagram
                            [displayTitle]="false"
                            [chartConfig]="deviceComplianceChart"
                            [legendTextCallback]="getChartLegendLabel"
                            legendPosition="right"
                            [isLoading]="isLoadingCharts" />
                        </div>
                      </microsec-card>
                    </div>
                    <div class="col-6">
                      <microsec-card [isBordered]="true" [isChartCard]="true" [isFull]="true">
                        <div class="font-bold mb-3">Device Distribution by Criticality/Compliance Score</div>
                        <div class="my-auto">
                          <microsec-common-chart
                            #deviceRiskComplianceDiagram
                            [displayTitle]="false"
                            [chartConfig]="deviceRiskComplianceChart"
                            [legendPosition]="null"
                            [isLoading]="isLoadingCharts" />
                        </div>
                      </microsec-card>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            @if (selectedOverview === 'question') {
              <app-assessment-report-question
                [showScoreSL]="showScoreSL"
                [sections]="sections"
                [rightTemplate]="!!assessmentReport?.devices?.length ? switchOverviewTemplate : undefined" />
            }
            @if (selectedOverview === 'device') {
              <app-assessment-report-device
                [showScoreSL]="showScoreSL"
                [devices]="devices"
                [rightTemplate]="!!assessmentReport?.devices?.length ? switchOverviewTemplate : undefined" />
            }
          </div>
        </div>
      </div>
    </microsec-common-preview-layout>
  </div>
</microsec-loading-overlay>

<ng-template #headerTemplate>
  <div class="w-full flex flex align-items-center justify-content-between gap-1">
    <div class="cursor-pointer hover:underline" (click)="dialogRef.close()">
      <i class="fas fa-angle-left mr-2"></i>{{ !!isDashboard ? 'Back to Dashboard' : 'Back to Assessments' }}
    </div>
    <div class="text-highlight font-bold">{{ util.getUppercaseFirstLetter(assessment?.name || '') }} Result</div>
    <div #reportMessageTarget>
      @if (
        !!checkReportFeatured &&
        !isLoading &&
        reportTypes.length &&
        (!assessment?.report_id || (!!assessment?.report_id && (!report || (!!report && report.status === REPORT_STATUSES.FAILED))))
      ) {
        <div [pTooltip]="!!checkReportQuota ? '' : 'Maximum number of reports reached'" tooltipPosition="bottom" appendTo="body">
          <p-button
            icon="fa fa-file-lines"
            label="Generate Report"
            severity="secondary"
            size="small"
            [rounded]="true"
            styleClass="text-transform-none"
            (onClick)="onCreateReport($event)"
            [disabled]="!!isLoading || !checkReportQuota">
          </p-button>
        </div>
      }
      <p-button
        *ngIf="!!assessment?.report_id && report?.status === REPORT_STATUSES.CREATED"
        icon="fas fa-circle-notch fa-spin"
        label="Processing"
        severity="success"
        size="small"
        [rounded]="true"
        styleClass="text-transform-none custom-button w-9rem"
        [disabled]="true">
      </p-button>
      <p-overlayPanel styleClass="custom-message-panel" #reportMessagePanel [dismissable]="false">
        <ng-template pTemplate="content">
          <div class="flex flex-column gap-2">
            <label class="font-bold text-sm">Generating Report</label>
            <label class="text-sm">
              Your report is currently being generated. Feel free to navigate away from this page. Rest assured, your report will be available
              shortly.<br />You can download it here or access it from the 'Reports' section on the main sidebar.
            </label>
            <div class="flex align-items-center justify-content-between">
              <div class="text-grey text-sm underline cursor-pointer" (click)="alwaysHideReportMessage()">Don't show again</div>
              <p-button type="button" size="small" label="Got It" severity="secondary" (onClick)="reportMessagePanel.hide()"></p-button>
            </div>
          </div>
        </ng-template>
      </p-overlayPanel>
      <ng-container *ngIf="!!assessment?.report_id && report?.status === REPORT_STATUSES.GENERATED">
        <p-button
          icon="fas fa-download"
          label="Download Report"
          severity="success"
          size="small"
          [rounded]="true"
          styleClass="text-transform-none"
          (onClick)="downloadReportMenu.toggle($event)"></p-button>
        <p-menu
          #downloadReportMenu
          styleClass="microsec-button-context-menu"
          [model]="downloadReportMenuOptions"
          [popup]="true"
          appendTo="body"></p-menu>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #switchOverviewTemplate>
  <p-button
    icon="fas fa-repeat"
    [label]="'Switch to ' + (selectedOverview === 'device' ? 'Question' : 'Device') + ' View'"
    severity="secondary"
    size="small"
    [rounded]="true"
    styleClass="text-transform-none"
    (onClick)="selectedOverview = selectedOverview === 'device' ? 'question' : 'device'">
  </p-button>
</ng-template>
